import { IconButton, ButtonProps } from 'rsuite';
import React, { HTMLAttributes, PropsWithChildren } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styled from 'styled-components';
// @ts-ignore
import _var from '../../styles/_variables.scss';

const DefaultStyledIconButton = styled(IconButton)`
  padding-left: 40px !important;
  padding-right: 15px !important;
  height: 40px !important;
  font-size: 14px;
`;

const PrimaryStyledIconButton = styled(DefaultStyledIconButton)`
  background: ${_var.primary};
  color: ${_var.white};
  &:hover {
    background: ${_var.primary};
    color: ${_var.white};
    opacity: 0.8;
    transition: opacity 0.3s linear;
  }
`;

const SubtleStyledIconButton = styled(DefaultStyledIconButton)`
  background: transparent;
  color: ${_var.textGray};
  &:hover,
  &:focus,
  &:focus:active {
    background: transparent;
    color: ${_var.primary};
    opacity: 0.8;
    transition: opacity 0.3s linear;
  }
`;

const GhostStyledIconButton = styled(DefaultStyledIconButton)`
  color: ${_var.primary};
  border: 0;
  &:hover,
  &:focus,
  &:focus:active {
    opacity: 0.8;
    transition: opacity 0.3s linear;
  }
`;

export const MaterialAddButton = (props: ButtonProps & PropsWithChildren<HTMLAttributes<{}>>) => {
  const { children, ...otherProps } = props;

  const StyledIconButton =
    props.appearance === 'primary'
      ? PrimaryStyledIconButton
      : props.appearance === 'subtle'
      ? SubtleStyledIconButton
      : props.appearance === 'ghost'
      ? GhostStyledIconButton
      : DefaultStyledIconButton;

  return (
    <StyledIconButton
      icon={
        <AddCircleOutlineIcon
          style={{
            position: 'absolute',
            top: 2,
            left: 4,
            fontSize: '14px',
            width: '36px',
            height: '36px',
            padding: '9px 0',
          }}
        />
      }
      {...otherProps}
    >
      {children}
    </StyledIconButton>
  );
};
