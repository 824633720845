import React from 'react';
import { useTranslation } from 'react-i18next';
import { YesNoColumn } from '../../../../../shared-components';
import { LabSummaryCertification } from '../types';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import DataTable from '../../../../../shared-components/table/DataTable';
import { QualificationsSummaryTableActions } from './QualificationsSummaryTableActions';
import { SummaryTableWithoutTabProps } from '../../../../../helpers/types';
import { Checkbox } from 'rsuite';
import { useDispatch } from 'react-redux';
import { updateSelectedCertificationProperty } from '../../../../../redux/modules/admin/atl-qualifications';
import { certificationStatusTitles } from '../../../../../helpers/constants/certification-statuses';

type QualificationsSummaryTableProps = Partial<SummaryTableWithoutTabProps<LabSummaryCertification>> & {
  data: LabSummaryCertification[];
  pagination?: boolean;
  creationMode?: boolean;
  deleteQualification: (certification: LabSummaryCertification) => void;
};

export const QualificationsSummaryTable = (props: QualificationsSummaryTableProps) => {
  const { data, pagination = false, creationMode = false, deleteQualification, ...rest } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setInteroperability = (certification: LabSummaryCertification, value: boolean) =>
    dispatch(updateSelectedCertificationProperty(certification, 'interoperability', value));

  const setConformance = (certification: LabSummaryCertification, value: boolean) =>
    dispatch(updateSelectedCertificationProperty(certification, 'conformance', value));

  return (
    <DataTable<LabSummaryCertification>
      sticky
      topOffset={80}
      expandable
      data={data}
      pagination={pagination}
      rowKey={cert => `row-${cert.version.id}`}
      {...rest}
    >
      <DataTableColumn wordWrap minWidth={70} field="id" label={t('certifications.id')}>
        {(row: LabSummaryCertification) => row.version.id}
      </DataTableColumn>
      <DataTableColumn wordWrap width="30%" field="name" label={t('certifications.name')} sortable />
      <DataTableColumn wordWrap width="30%" field="version" label={t('certifications.version.version')}>
        {(row: LabSummaryCertification) => row.version.name}
      </DataTableColumn>
      <DataTableColumn wordWrap width="30%" field="role" label={t('certifications.role')} sortable>
        {(row: LabSummaryCertification) => row.role.name}
      </DataTableColumn>
      <DataTableColumn wordWrap minWidth={150} field="status" label={t('certifications.status')}>
        {(row: LabSummaryCertification) => certificationStatusTitles[row.version.status]}
      </DataTableColumn>
      <DataTableColumn minWidth={150} field="interoperability" label={t('applications.labs.interoperability')}>
        {(row: LabSummaryCertification) =>
          creationMode ? (
            <Checkbox
              checked={row.version.interoperability}
              onChange={(_, checked) => setInteroperability(row, checked)}
            />
          ) : (
            <YesNoColumn value={row.version.interoperability} />
          )
        }
      </DataTableColumn>
      <DataTableColumn minWidth={150} field="conformance" label={t('applications.labs.conformance')}>
        {(row: LabSummaryCertification) =>
          creationMode ? (
            <Checkbox checked={row.version.conformance} onChange={(_, checked) => setConformance(row, checked)} />
          ) : (
            <YesNoColumn value={row.version.conformance} />
          )
        }
      </DataTableColumn>
      <DataTableColumn minWidth={100} field="actions" label={t('common.actions.title')} style={{ textAlign: 'center' }}>
        {(certification: LabSummaryCertification) => (
          <QualificationsSummaryTableActions
            creationMode={creationMode}
            certification={certification}
            deleteQualification={deleteQualification}
          />
        )}
      </DataTableColumn>
    </DataTable>
  );
};
