import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { DataTablePagination } from './DataTablePagination';
import { DataTableHead } from './DataTableHead';
import { DataTableRow } from './DataTableRow';
import { DataTableColumnProps } from './DataTableColumn';
import { DataTableNoDataMessage } from './DataTableNoDataMessage';
import { DataTableDataLoader } from './DataTableDataLoader';
import {
  DataTableExpandProps,
  DataTablePaginationProps,
  DataTableRowKeyProps,
  DataTableRowOptionsProps,
  DataTableSortingProps,
  SetActivePageAction,
} from './types';
import { DataTableStickyWrapper } from './DataTableStickyWrapper';
import { DataTableHeadComponent, DataTableHeadComponentProps } from './DataTableHeadComponent';
import { TableFooter } from '@material-ui/core';

/**
 * Generates a unique row key. A unique key is required for correct table component re-rendering.
 * @param rowKey can be a string key (a data field name) or a function that generates a key based on the entity
 * @param row data entity
 */
export function getRowKey<T>(rowKey: string | ((v: T) => string), row: T): string {
  return typeof rowKey === 'function' ? rowKey(row) : String(row[rowKey as keyof T]);
}

export type DataTableChildType<T> = React.ReactElement<DataTableColumnProps<T>> | false;

/**
 * Validates table column's settings
 * @param setting
 */
function validateColumnsSetting<T>(setting: DataTableColumnProps<T>) {
  if (setting.fixed && (!setting.width || isNaN(setting.width as number) || setting.wordWrap)) {
    throw new Error('Invalid fixed column settings');
  }
}

/**
 * Reads table column's settings
 * @param children
 */
function getColumnSettings<T>(children: DataTableChildType<T>[] | DataTableChildType<T>): DataTableColumnProps<T>[] {
  const settings = [] as DataTableColumnProps<T>[];
  React.Children.forEach(children, child => {
    if (child) {
      validateColumnsSetting(child.props);
      settings.push(child.props);
    }
  });

  return settings;
}

type DataTableProps<T> = Partial<DataTablePaginationProps> &
  Partial<DataTableSortingProps<T>> &
  Partial<DataTableExpandProps> &
  Partial<DataTableRowOptionsProps<T>> &
  DataTableRowKeyProps<T> & {
    data: T[];
    pagination?: boolean;
    sticky?: boolean;
    topOffset?: number;
    loading?: boolean;
    expandable?: boolean;
    minWidth?: number;
    className?: string;
    warningRedBorder?: (v: T) => boolean;
    warningRowChildrenBorder?: (v: T) => boolean;
    rowKey?: string | ((v: T) => string);
    children: DataTableChildType<T>[] | DataTableChildType<T>;
    TableHeadComponent?: (props: DataTableHeadComponentProps<T>) => JSX.Element;
    TableFooterComponent?: (props: {}) => JSX.Element;
    defaultExpanded?: boolean;
    useEnvIdentifier?: boolean;
  };

export default function DataTable<T extends { id: number; children?: T[] }>(props: DataTableProps<T>) {
  const { loading, data, children, className, rowKey = 'id' } = props;
  const {
    expandable = false,
    defaultExpanded = false,
    sticky = false,
    pagination = false,
    minWidth,
    topOffset,
    warningRedBorder,
    warningRowChildrenBorder,
    useEnvIdentifier = false,
  } = props;
  const {
    total,
    activePage,
    setActivePage,
    sorting,
    onChangeSorting,
    onExpand,
    expandedRows,
    getRowOptions,
    showLastBtn,
  } = props;
  const { TableHeadComponent = DataTableHeadComponent, TableFooterComponent, getRowClassName } = props;

  if (pagination && (total === undefined || activePage === undefined || setActivePage === undefined)) {
    throw new Error('Invalid pagination settings');
  }

  const settings = getColumnSettings<T>(children);

  const totalRecords = pagination ? total : data.length;

  return (
    <TableContainer className={className}>
      <DataTableStickyWrapper
        loading={loading}
        sticky={sticky}
        minWidth={minWidth}
        topOffset={topOffset}
        header={() => (
          <DataTableHead
            sorting={sorting}
            settings={settings}
            expandable={expandable}
            onChangeSorting={onChangeSorting}
            TableHeadComponent={TableHeadComponent}
            defaultExpanded={defaultExpanded}
          />
        )}
        useEnvIdentifier={useEnvIdentifier}
      >
        <TableBody>
          {data.map((row, index) => (
            <DataTableRow
              row={row}
              rowKey={rowKey}
              settings={settings}
              onExpand={onExpand}
              expandable={expandable}
              defaultExpanded={defaultExpanded}
              expandedRows={expandedRows}
              getRowOptions={getRowOptions}
              getRowClassName={getRowClassName}
              striped={index % 2 === 0}
              key={getRowKey<T>(rowKey, row)}
              warningRedBorder={warningRedBorder ? warningRedBorder(row) : false}
              warningRowChildrenBorder={warningRowChildrenBorder ? warningRowChildrenBorder(row) : false}
            />
          ))}
        </TableBody>
        {TableFooterComponent && (
          <TableFooter>
            <TableFooterComponent />
          </TableFooter>
        )}
      </DataTableStickyWrapper>
      {!loading && totalRecords === 0 && <DataTableNoDataMessage />}
      {pagination && (
        <DataTablePagination
          total={total as number}
          activePage={activePage as number}
          setActivePage={setActivePage as SetActivePageAction}
          showLastBtn={showLastBtn}
        />
      )}
      {loading && <DataTableDataLoader />}
    </TableContainer>
  );
}
