import { BasicRole } from '../../../../redux/modules/roles/types';
import { ImpersonateAutoCompleteItem } from '../../../partials/impersonate/types';
import { CertificationStatuses } from '../../../../helpers/constants/certification-statuses';

export type Lab = ImpersonateAutoCompleteItem & { subtype: LabSubType };

export enum LabSubType {
  ATL = 'ATL',
  CATL = 'CATL',
  MATL = 'MATL',
  MRCL = 'MRCL',
  MCTL = 'MCTL',
  STL = 'STL',
}

export const labSubTypeTitles = {
  [LabSubType.ATL]: 'ATL',
  [LabSubType.CATL]: 'CATL',
  [LabSubType.MATL]: 'MATL',
  [LabSubType.MRCL]: 'MRCL',
  [LabSubType.MCTL]: 'MCTL',
  [LabSubType.STL]: 'STL',
};
interface LabCertificationVersion {
  id: number;
  name: string;
  status: CertificationStatuses;
  conformance: boolean;
  interoperability: boolean;
}

export interface LabCertification {
  id: number;
  name: string;
  role: BasicRole;
  version: LabCertificationVersion[];
}

export type LabSummaryCertification = Omit<LabCertification, 'version'> & {
  version: LabCertificationVersion;
  children?: LabSummaryCertification[];
};

export interface Qualification {
  certification: LabSummaryCertification;
  interoperability: boolean;
  conformance: boolean;
}
