import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete as MaterialAutocomplete, Value } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete/Autocomplete';

import { ErrorObject } from '../../helpers/types';
// @ts-ignore
import _var from '../../styles/_variables.scss';

interface LabeledFieldProps {
  errors?: ErrorObject;
}

const useStyles = makeStyles({
  option: {
    fontSize: 14,
    '&[data-focus="true"]': {
      backgroundColor: _var.secondary,
    },
    '&[aria-selected="true"]': {
      fontWeight: 'bold',
      color: _var.primary,
      backgroundColor: _var.secondary,
    },
  },
});

const DefaultRenderInput = (params: AutocompleteRenderInputParams & { placeholder?: string }) => (
  <TextField {...params} fullWidth size="small" variant="outlined" />
);

export interface GenericAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> {
  multiple?: Multiple; // If `true`, `value` must be an array and the menu will support multiple selections.
  freeSolo?: FreeSolo; // If `true`, the user input is not bound to provided options.
  disableClearable?: DisableClearable; // If `true`, the input can't be cleared.
  value?: Value<T, Multiple, DisableClearable, FreeSolo>;
  onChange: (value: Value<T, Multiple, DisableClearable, FreeSolo>) => void;
  className?: string;
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  filterSelectedOptions?: boolean;
  groupBy?: (option: T) => string;
  getOptionLabel: (option: T) => string;
  getOptionDisabled?: (option: T) => boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderOption?: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
}

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends GenericAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  options: T[];
  loading?: boolean;
  onInputChange?: (value: string) => void;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { onChange, placeholder, renderInput, onInputChange, ...rest } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const renderInputHandler = useCallback(
    (params: AutocompleteRenderInputParams) =>
      renderInput ? (
        renderInput(params)
      ) : (
        <DefaultRenderInput placeholder={placeholder ?? t('common.placeholders.autoComplete')} {...params} />
      ),
    [renderInput],
  );

  return (
    <MaterialAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      autoComplete
      fullWidth
      closeIcon={null}
      classes={classes}
      renderInput={renderInputHandler}
      loadingText={t('common.placeholders.loadingData')}
      noOptionsText={t('common.placeholders.noResultsFound')}
      onChange={(event, value) => {
        if (value) {
          onChange(value);
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (onInputChange) {
          onInputChange(newInputValue);
        }
      }}
      {...rest}
    />
  );
}
