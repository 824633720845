import { LabCertification, LabSummaryCertification } from './types';

export const reformatData = (data: LabCertification[], merge = false): LabSummaryCertification[] => {
  const result: LabSummaryCertification[] = [];
  data.forEach(certification => {
    certification.version.forEach(version => {
      const record = { ...certification, version, children: [] };
      const parent = merge ? result.find(value => value.id === record.id) : null;
      (merge && parent ? parent.children : result)?.push(record);
    });
  });
  return result;
};
