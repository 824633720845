import { AnyAction } from 'redux';
import {
  HIDE_UPDATE_QUALIFICATIONS_MODAL,
  LAB_QUALIFICATION_REMOVED,
  LOAD_AVAILABLE_CERTIFICATIONS_LIST,
  LOAD_LAB_INFO,
  LOAD_LABS_LIST,
  REMOVE_LAB_QUALIFICATION,
  RESET_STATE,
  ADD_LAB_CERTIFICATIONS,
  SET_AVAILABLE_CERTIFICATIONS_LIST,
  SET_LAB_INFO,
  SET_LABS_LIST,
  SHOW_UPDATE_QUALIFICATIONS_MODAL,
  UPDATE_SELECTED_CERTIFICATION_PROPERTY,
  REMOVE_SELECTED_CERTIFICATION,
  PUBLISH_SELECTED_CERTIFICATIONS,
  SELECTED_CERTIFICATIONS_PUBLISHED,
  PUBLISH_SELECTED_CERTIFICATIONS_ERROR,
} from './actionTypes';
import {
  Lab,
  LabSummaryCertification,
  Qualification,
} from '../../../../components/pages/admin/atl-qualifications/types';
import { LabInfo } from '../../../../components/pages/lab-info/types';
import { jsonCopy } from '../../../../helpers';
import { reformatData } from '../../../../components/pages/admin/atl-qualifications/helpers';

const initialState: AtlQualificationsState = {
  labs: [],
  isLoadingLabs: false,
  labInfo: null,
  isLoadingLabInfo: false,
  availableCertifications: [],
  isLoadingAvailableCertifications: false,
  modal: {
    isOpen: false,
  },
  isRemoving: false,
  selectedCertifications: [],
  isPublishing: false,
};

export interface AtlQualificationsState {
  labs: Lab[];
  isLoadingLabs: boolean;
  labInfo: LabInfo | null;
  isLoadingLabInfo: boolean;
  availableCertifications: LabSummaryCertification[];
  isLoadingAvailableCertifications: boolean;
  modal: {
    isOpen: boolean;
    certification?: LabSummaryCertification;
  };
  isRemoving: boolean;
  selectedCertifications: Qualification[];
  isPublishing: boolean;
}

export const atlQualificationsReducer = (state: AtlQualificationsState = initialState, action: AnyAction) => {
  const { type, payload } = action;
  switch (type) {
    case RESET_STATE:
      return { ...initialState } as AtlQualificationsState;

    case LOAD_LABS_LIST:
      return { ...initialState, isLoadingLabs: true } as AtlQualificationsState;

    case SET_LABS_LIST:
      return { ...state, isLoadingLabs: false, labs: payload } as AtlQualificationsState;

    case LOAD_LAB_INFO:
      return { ...state, labInfo: null, isLoadingLabInfo: true } as AtlQualificationsState;

    case SET_LAB_INFO:
      return { ...state, labInfo: payload, isLoadingLabInfo: false } as AtlQualificationsState;

    case LOAD_AVAILABLE_CERTIFICATIONS_LIST:
      return {
        ...state,
        availableCertifications: [],
        isLoadingAvailableCertifications: true,
      } as AtlQualificationsState;

    case SET_AVAILABLE_CERTIFICATIONS_LIST:
      return {
        ...state,
        availableCertifications: reformatData(payload).sort((a, b) => a.name.localeCompare(b.name)),
        isLoadingAvailableCertifications: false,
      } as AtlQualificationsState;

    case SHOW_UPDATE_QUALIFICATIONS_MODAL:
      return {
        ...state,
        modal: { isOpen: true, certification: action.payload as LabSummaryCertification },
      } as AtlQualificationsState;

    case HIDE_UPDATE_QUALIFICATIONS_MODAL:
      return { ...state, modal: { isOpen: false } } as AtlQualificationsState;

    case REMOVE_LAB_QUALIFICATION:
      return { ...state, isRemoving: true } as AtlQualificationsState;

    case LAB_QUALIFICATION_REMOVED:
      return { ...state, isRemoving: false } as AtlQualificationsState;

    case ADD_LAB_CERTIFICATIONS:
      return {
        ...state,
        selectedCertifications: [...state.selectedCertifications, ...action.payload],
      } as AtlQualificationsState;

    case UPDATE_SELECTED_CERTIFICATION_PROPERTY:
      const selectedCertifications = jsonCopy(state.selectedCertifications);
      selectedCertifications.forEach(selected => {
        if (selected.certification.version.id === payload.certification.version.id) {
          selected[payload.property as keyof Qualification] = payload.value;
        }
      });
      return { ...state, selectedCertifications } as AtlQualificationsState;

    case REMOVE_SELECTED_CERTIFICATION:
      return {
        ...state,
        selectedCertifications: jsonCopy(state.selectedCertifications).filter(
          selected => selected.certification.version.id !== payload.version.id,
        ),
      } as AtlQualificationsState;

    case PUBLISH_SELECTED_CERTIFICATIONS:
      return { ...state, isPublishing: true } as AtlQualificationsState;

    case SELECTED_CERTIFICATIONS_PUBLISHED:
      return { ...state, isPublishing: false, selectedCertifications: [] } as AtlQualificationsState;

    case PUBLISH_SELECTED_CERTIFICATIONS_ERROR:
      return { ...state, isPublishing: false } as AtlQualificationsState;

    default:
      return state;
  }
};
