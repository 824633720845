import React from 'react';
import { useTranslation } from 'react-i18next';

import { LabSubType, labSubTypeTitles } from '../types';
import { LabsSummaryFiltersObject } from '../../../../../helpers/types';
import AdvancedFilter from '../../../../../shared-components/filters/AdvancedFilter';
import {
  KeywordSearchCondition,
  DropdownCondition,
  generateKeywordSerachConditionInitParameterValue,
  generateDropdownConditionInitParameterValue,
  getKeywordSerachConditionCurrentValue,
  getDropdownConditionCurrentValue,
} from '../../../../../shared-components/filters/conditions';
import { FilterParametersType } from '../../../../../shared-components/filters/types';

interface LabDataItemType {
  label: string;
  value: string;
}

const labSubTypes: LabDataItemType[] = [
  { value: LabSubType.ATL, label: labSubTypeTitles[LabSubType.ATL] },
  { value: LabSubType.CATL, label: labSubTypeTitles[LabSubType.CATL] },
  { value: LabSubType.MATL, label: labSubTypeTitles[LabSubType.MATL] },
  { value: LabSubType.MCTL, label: labSubTypeTitles[LabSubType.MCTL] },
  { value: LabSubType.MRCL, label: labSubTypeTitles[LabSubType.MRCL] },
  { value: LabSubType.STL, label: labSubTypeTitles[LabSubType.STL] },
];

interface Props {
  appliedFilters: LabsSummaryFiltersObject;
  updateFilters: (value: LabsSummaryFiltersObject) => void;
}

const Filters = ({ appliedFilters, updateFilters }: Props) => {
  const { t } = useTranslation();

  const handleConditionChange = (filterParameters: FilterParametersType) => {
    const newFilterObject: LabsSummaryFiltersObject = {
      search: null,
      subtype: null,
    };
    if (filterParameters.search) {
      newFilterObject.search = getKeywordSerachConditionCurrentValue(filterParameters.search);
    }
    if (filterParameters.subtype) {
      newFilterObject.subtype = getDropdownConditionCurrentValue(filterParameters.subtype) as LabSubType[];
    }
    updateFilters(newFilterObject);
  };

  const getInitParameters = (): FilterParametersType => {
    const initParameters: FilterParametersType = {};

    if (appliedFilters) {
      if (appliedFilters.search) {
        initParameters['search'] = generateKeywordSerachConditionInitParameterValue(
          decodeURIComponent(appliedFilters.search),
          false,
        );
      }

      if (appliedFilters.subtype) {
        initParameters['subtype'] = generateDropdownConditionInitParameterValue(
          appliedFilters.subtype,
          true,
          labSubTypes,
        );
      }
    }

    return initParameters;
  };

  const initParameters = getInitParameters();

  return (
    <AdvancedFilter
      inlineConditions={[
        { conditionType: KeywordSearchCondition, parameterName: 'search' },
        {
          conditionType: DropdownCondition,
          parameterName: 'subtype',
          options: {
            data: labSubTypes,
            placeholder: t('admin.qualifications.subTypeSelectPlaceholder'),
            multiple: true,
            checkbox: true,
            limitTags: 2,
          },
        },
      ]}
      onConditionChange={handleConditionChange}
      initParameters={initParameters}
    />
  );
};

export const LabsSummaryAdvancedFilters = React.memo(Filters);
