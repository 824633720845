import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Modal } from 'rsuite';
import { useDispatch } from 'react-redux';
import { LabSummaryCertification } from '../types';
import { hideUpdateQualificationModal } from '../../../../../redux/modules/admin/atl-qualifications';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    color: '#641246',
    '&:hover': {
      color: '#641246',
    },
    marginRight: 10,
  },
  modal: { top: '25%' },
}));

interface EditQualificationModalProps {
  certification: LabSummaryCertification;
  updateQualification: (
    certification: LabSummaryCertification,
    interoperability: boolean,
    conformance: boolean,
  ) => void;
}

export const EditQualificationModal = ({ certification, updateQualification }: EditQualificationModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [conformance, setConformance] = useState(certification.version.conformance);
  const [interoperability, setInteroperability] = useState(certification.version.interoperability);

  const onClose = () => dispatch(hideUpdateQualificationModal());

  const onSave = () => {
    setSaving(true);
    updateQualification(certification, interoperability, conformance);
  };

  return (
    <Modal backdrop show onHide={onClose} className={classes.modal}>
      <Modal.Header>
        <Modal.Title>{t('certifications.modalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'hidden' }}>
        <Checkbox
          className="mr-1 interoperability-checkbox"
          checked={interoperability}
          onChange={(_, checked) => setInteroperability(checked)}
        >
          {t('applications.labs.interoperability')}
        </Checkbox>
        <Checkbox
          className="mr-1 conformance-checkbox"
          checked={conformance}
          onChange={(_, checked) => setConformance(checked)}
        >
          {t('applications.labs.conformance')}
        </Checkbox>
      </Modal.Body>
      <div className={classes.btnWrapper}>
        <Button
          className={classes.cancelButton}
          disableRipple
          onClick={onClose}
          appearance="subtle"
          variant="contained"
        >
          {t('common.navigation.cancel')}
        </Button>
        <Button appearance="primary" onClick={onSave} disabled={!conformance && !interoperability} loading={saving}>
          {t('common.navigation.save')}
        </Button>
      </div>
    </Modal>
  );
};
