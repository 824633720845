import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton } from 'rsuite';
import { Lab } from '../types';
import { DataTableColumn } from '../../../../../shared-components/table/DataTableColumn';
import DataTable from '../../../../../shared-components/table/DataTable';
import { SortingOptions, SortType } from '../../../../../components/pages/test-case/types';

export const LabsSummaryTable = ({
  data,
  loading,
  sorting,
  onChangeSorting,
}: {
  data: Lab[];
  loading: boolean;
  sorting: SortingOptions;
  onChangeSorting: (sortColumn: string, sortType: SortType) => void;
}) => {
  const { t } = useTranslation();

  // do sort
  const sortedLabs = data.sort((a: Lab, b: Lab) => {
    let sortable = 0;
    if (sorting.sortColumn === 'name') {
      sortable = a.name.localeCompare(b.name);
    }
    if (sorting.sortColumn === 'id') {
      sortable = a.id - b.id;
    }
    if (sorting.sortColumn === 'subtype') {
      sortable = a.subtype.localeCompare(b.subtype);
    }
    return sortable * (sorting.sortType === 'asc' ? 1 : -1);
  });

  const onView = (id: number) => window.open(`#/admin/atl-qualifications/${id}`);

  return (
    <DataTable<Lab>
      sticky
      data={sortedLabs}
      loading={loading}
      sorting={sorting}
      onChangeSorting={onChangeSorting}
      className="mb-2"
      useEnvIdentifier={true}
    >
      <DataTableColumn wordWrap minWidth={70} field="id" label={t('certifications.id')} sortable />
      <DataTableColumn wordWrap width="60%" field="name" label={t('certifications.name')} sortable />
      <DataTableColumn
        wordWrap
        width="30%"
        field="subtype"
        label={t('admin.qualifications.labsSubTypeTitle')}
        sortable
      />
      <DataTableColumn minWidth={100} field="actions" label={t('common.actions.title')} style={{ textAlign: 'center' }}>
        {(lab: Lab) => (
          <div className="actions text-center">
            <IconButton onClick={() => onView(lab.id)} icon={<Icon icon="eye" className="pr-1" />} appearance="link" />
          </div>
        )}
      </DataTableColumn>
    </DataTable>
  );
};
