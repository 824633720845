import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useStickyStyles } from './DataTableStickyWrapper';
import { DataTableHeadProps } from './DataTableHead';
import { DataTableHeadCell, StyledTableHeadCell } from './DataTableHeadCell';

export const useExpandColumnStyles = makeStyles(() => ({
  expandColumn: {
    padding: 0,
    width: '2.1%',
    minWidth: 30,
  },
}));

export interface DataTableHeadComponentProps<T> extends Omit<DataTableHeadProps<T>, 'TableHeadComponent'> {
  getSortHandler: (property: string) => () => void;
  defaultExpanded: boolean;
}

export function DataTableHeadComponent<T>(props: DataTableHeadComponentProps<T>) {
  const { sorting, settings, expandable, getSortHandler, defaultExpanded = false } = props;
  const classes = useExpandColumnStyles();
  const stickyClasses = useStickyStyles();

  return (
    <TableHead>
      <TableRow>
        {settings
          .filter(column => column.prefix === true)
          .map(column => (
            <DataTableHeadCell key={column.field} settings={column} sorting={sorting} getSortHandler={getSortHandler} />
          ))}
        {expandable && !defaultExpanded && (
          <StyledTableHeadCell className={classNames(classes.expandColumn, stickyClasses.headerColumn)} />
        )}
        {settings
          .filter(column => column.prefix !== true)
          .map(column => (
            <DataTableHeadCell key={column.field} settings={column} sorting={sorting} getSortHandler={getSortHandler} />
          ))}
      </TableRow>
    </TableHead>
  );
}
