import React from 'react';
import { Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LabSummaryCertification } from '../types';
import { defaultWhisperProps } from '../../../../../helpers/constants';
import { Confirm } from '../../../../../helpers/confirmationPopup/Confirm';
import { showUpdateQualificationModal } from '../../../../../redux/modules/admin/atl-qualifications';

interface QualificationsSummaryTableActionsProps {
  creationMode?: boolean;
  certification: LabSummaryCertification;
  deleteQualification: (certification: LabSummaryCertification) => void;
}

export const QualificationsSummaryTableActions = (props: QualificationsSummaryTableActionsProps) => {
  const { certification, creationMode = false, deleteQualification } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onEdit = () => dispatch(showUpdateQualificationModal(certification));

  const onDelete = () => {
    return Confirm({
      title: t('common.placeholders.areYouSure'),
      message: t('common.placeholders.cantBeUndone'),
      onAccept: () => dispatch(deleteQualification(certification)),
    });
  };

  const onView = () => window.open(`#/certification/${certification.version.id}`);

  return (
    <div className="actions">
      <IconButton onClick={onView} icon={<Icon icon="eye" />} appearance="link" />
      <Whisper
        {...defaultWhisperProps}
        speaker={
          <Popover full>
            {!creationMode && (
              <Dropdown.Menu className="action-icons">
                <Dropdown.Item onClick={onEdit}>
                  <IconButton icon={<Icon icon="edit" />} appearance="link">
                    {t('common.actions.edit')}
                  </IconButton>
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
            <Dropdown.Menu className="action-icons">
              <Dropdown.Item onClick={onDelete}>
                <IconButton icon={<Icon icon="trash-o" />} appearance="link">
                  {t('common.actions.remove')}
                </IconButton>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Popover>
        }
      >
        <IconButton appearance="link" icon={<Icon icon="more" className="icon pointer medium" />} />
      </Whisper>
    </div>
  );
};
