import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckBoxIcon from '@material-ui/icons/CheckBoxSharp';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox } from 'rsuite';

import { Autocomplete } from '../../../../../shared-components/inputs/Autocomplete';
import { QualificationsInputSuggestion } from './QualificationsInputSuggestion';
import LifeCycleStatusBadge from '../../../certification/partials/version/LifeCycleStatusBadge';
import { LabSummaryCertification } from '../types';
import { getLifeCycleStatusKey } from '../../../../../helpers/constants';
// @ts-ignore
import _var from '../../../../../styles/_variables.scss';

const StyledFormWrapper = styled.div`
  display: flex;
  align-content: center;

  .rs-checkbox,
  .rs-btn {
    align-self: flex-end;
  }
`;

const useStyles = makeStyles({
  root: {
    fontSize: 24,
  },
});

interface QualificationsInputProps {
  loading: boolean;
  certifications: LabSummaryCertification[];
  disabledCertifications?: LabSummaryCertification[];
  onSelect: (certs: LabSummaryCertification[], interoperability: boolean, conformance: boolean) => void;
}

export const QualificationsInput = (props: QualificationsInputProps) => {
  const { loading, certifications, onSelect, disabledCertifications = [] } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const uncheckedIcon = <CheckBoxOutlineBlankIcon classes={classes} />;
  const checkedIcon = <CheckBoxIcon classes={classes} style={{ color: _var.primary }} />;

  const [selectedCertifications, setSelectedCertifications] = useState<LabSummaryCertification[]>([]);
  const [conformance, setConformance] = useState(false);
  const [interoperability, setInteroperability] = useState(false);

  const handleSelect = () => {
    onSelect(selectedCertifications, interoperability, conformance);
    setSelectedCertifications([]);
    setInteroperability(false);
    setConformance(false);
  };

  return (
    <StyledFormWrapper>
      <Autocomplete<LabSummaryCertification, true>
        multiple
        className="mr-1"
        disableCloseOnSelect
        value={selectedCertifications}
        onChange={setSelectedCertifications}
        options={certifications}
        loading={loading}
        getOptionDisabled={option => disabledCertifications.some(value => value.id === option.id)}
        groupBy={cert => `${cert.name} (${cert.role.name})`}
        getOptionLabel={cert =>
          `${cert.name} [${cert.version.id} - ${cert.version.name}] (${cert.role.name}) (${t(
            'certifications.lifecycle.status.' + getLifeCycleStatusKey(cert.version.status),
          )})`
        }
        renderOption={(item, { selected }) => (
          <>
            <MuiCheckbox icon={uncheckedIcon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            <QualificationsInputSuggestion item={item} />
            <LifeCycleStatusBadge status={item.version.status} />
          </>
        )}
        placeholder={t('common.placeholders.chooseCertification')}
      />
      <Checkbox className="mr-1" checked={interoperability} onChange={(_, checked) => setInteroperability(checked)}>
        {t('applications.labs.interoperability')}
      </Checkbox>
      <Checkbox className="mr-1" checked={conformance} onChange={(_, checked) => setConformance(checked)}>
        {t('applications.labs.conformance')}
      </Checkbox>
      <Button
        appearance="primary"
        className="add-qualification"
        onClick={handleSelect}
        style={{ minWidth: 110 }}
        disabled={!selectedCertifications.length || (!conformance && !interoperability)}
      >
        {t('admin.qualifications.addCertification')}
      </Button>
    </StyledFormWrapper>
  );
};
